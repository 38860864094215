import React, { useEffect, useRef } from 'react';
import './imageSlider.css'; // Assuming you have moved the styles to a CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons';

const ImageSlider = () => {
  const sliderRef = useRef(null);
  const topImageContainerRef = useRef(null);
  const sliderContainerRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const topImageContainer = topImageContainerRef.current;
    const sliderContainer = sliderContainerRef.current;

    const handleMove = (clientX) => {
      let rect = sliderContainer.getBoundingClientRect();
      let offsetX = clientX - rect.left;

      if (offsetX < 0) offsetX = 0;
      if (offsetX > rect.width) offsetX = rect.width;

      slider.style.left = offsetX + 'px';
      topImageContainer.style.clip = `rect(0, ${offsetX}px, auto, 0)`;
    };

    const handleMouseMove = (e) => {
      handleMove(e.clientX);
    };

    const handleTouchMove = (e) => {
      handleMove(e.touches[0].clientX);
    };

    const endMove = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', endMove);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', endMove);
    };

    const startMove = (e) => {
      e.preventDefault();
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', endMove);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', endMove);
    };

    slider.addEventListener('mousedown', startMove);
    slider.addEventListener('touchstart', startMove);

    const initializeSliderPosition = () => {
      let initialPosition = sliderContainer.getBoundingClientRect().width / 2 * 1.7;
      console.log(initialPosition);
      slider.style.left = `${initialPosition}px`;
      topImageContainer.style.clip = `rect(0, ${initialPosition}px, auto, 0)`;
    };

    window.addEventListener('load', initializeSliderPosition);

    return () => {
      slider.removeEventListener('mousedown', startMove);
      slider.removeEventListener('touchstart', startMove);
      window.removeEventListener('load', initializeSliderPosition);
    };
  }, []);

  return (
    <div className="slider-container flex justify-center mx-auto" ref={sliderContainerRef}>
      <img src="/cover1.jpg" className="image" alt="Background" />
      <div className="top-image" id="top-image-container" ref={topImageContainerRef}>
        <img src="/Image1.jpg" id="top-image" alt="Foreground" />
      </div>
      <div className="slider" id="slider" ref={sliderRef}>
        <div className="dotted-line"></div>
        <FontAwesomeIcon icon={faArrowsAltH} className="slider-icon" />
      </div>
    </div>
  );
};

export default ImageSlider;
