// src/components/TitleComponent.js
import React from 'react';
import './TitleComponent.css';

function TitleComponent() {
  return (
    <div className="title-container">
      <span className="text-white font-first">THE</span>
      <span className="text-mint heading font-first"> WIF</span>
      <span className="text-white font-first api-tool"> CREATOR TOOL</span>
    </div>
  );
}

export default TitleComponent;
