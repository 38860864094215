import React from 'react';
// import TopNavBar from './components/topbar/topbar';
import TitleComponent from './components/api tool/apitool';
import Introduction from './components/interductuon/interduction';
import ImageStyleSelection from './components/explanation/ImageStyleSelection';
import StyledTextWithEffects from './components/Subtitle/StyledTextWithEffects';
import HowToBuy from './components/howToBuy/HowToBuy';
import Community from './components/community/community';
import Footer from './components/footer/footer';
import './index.css'; // Import your CSS file here
import ImageSlider from './components/imageSlider/imageSlider';
import DashedDivider from './components/components/splitters';
import MainTitle from './components/Title/title';
import VideoPlayer from './components/video/videoPlayer';
import DEXToolsChartWidget from './components/dexTools/dextools';
import NavBar from './components/navbar/navbar';
import Jupiter from './jupiter/jupiter';

function App() {


  return (
    <div className="min-h-screen bg-darkerGrey">
      <NavBar />
      <div className="pt-20"> {/* Add padding to push content below fixed Nav */}
          <MainTitle />
        <div className="coms">
          <p className='font-first text-white flex justify-center bg-mainGray'>(COMMUNITY)</p>
        </div>
        <div className="mb-4"> {/* Add margin to push content below previous element */}
          <StyledTextWithEffects />
          <ImageSlider />
          <DashedDivider />
        </div>
      </div>
      <div id="section1" className="section bg-mainGray p-8 rounded-lg shadow-lg mt-8">
        <Introduction />
      </div>
      <div id="section2" className="bg-darkerGrey p-4 rounded-lg shadow-lg mt-8">
        <TitleComponent />
        <p className="text font-first mt-8 mb-8 max-w-1200">
          WE WANT EVERY DOG TO HAVE THEIR OWN UNIQUE IMAGE. <span className="highlighting">WIF</span> HAS GOT HIS. THIS <span className="highlight">WIF CREATOR TOOL</span>  GIVES YOU THAT POSSIBILITY. 
        </p>
        <VideoPlayer />
        <div className="parent-container">
          <iframe
            src="https://crypto-meme-maker.vercel.app/" // Replace with your URL
            title="Make your wif"
            width="100%"
            height="900px" // Adjust height as needed
            className="iframe-container"
            style={{ border: 'none' }}
          ></iframe>
          <Jupiter />
        </div>
        <ImageStyleSelection />
      </div>
      <div id="section3" className="section bg-mainGray p-8 rounded-lg shadow-lg">
        <DEXToolsChartWidget id="#section3Inside"/>
      </div>
      <div id="section4" className="section bg-darkerGrey p-8 rounded-lg shadow-lg">
        <HowToBuy />
      </div>
      <div id="section5" className="section bg-mainGray p-8 rounded-lg shadow-lg">
        <Community />
      </div>
      <Footer className="bg-mainGray"/>
    </div>
  );
}

export default App;
